.innerBannerAll{padding: 90px 0 0 0; margin-top: 90px;}
.innerBannerImg .container{z-index: 1;}
.innerBannerImg { background-position: center; background-size: cover; min-height: clamp(20rem, 3.785rem + 33.825vw, 44.375rem); display: flex; justify-content: center; align-items: center; position: relative;}
.innerBannerImg::before{content: ''; position: absolute; left: 0; top: 0; height: 100%; width: 55%; background: linear-gradient(to right,  rgba(0,0,0,0.65) 35%,rgba(0,0,0,0) 100%); }
.innerBannerText{ color: #FFF; padding: 50px 0;}
.innerBannerText h1{font-size: clamp(2.125rem, 1.044rem + 2.255vw, 3.75rem); font-weight: 700; line-height: 1.2em; margin-bottom: clamp(1.25rem, 0.834rem + 0.867vw, 1.875rem); letter-spacing: 1px; margin-top: 22px;}
.innerBannerText p{ font-size: clamp(1rem, 0.917rem + 0.173vw, 1.125rem); line-height: 1.2; font-weight: 300;}
.innerBannerText span{ display: block;}
.innerBannerText span::before { content: ''; background-color: var(--cm-cherryTwo); height: 12px; width: 12px; display: inline-block; margin-bottom: -1px; margin-right: 15px; }
.artsBannerTop .innerBannerText span::before{background-color: var(--cm-yellow);}
.entertainmentBannerTop .innerBannerText span::before{background-color: var(--cm-skyeTwo);}
:is(.sportsBannerTop, .entertainmentBannerTop, .artsBannerTop) .btn.btn-danger{width: fit-content; min-width: 220px; text-transform: uppercase; font-size: clamp(0.875rem, 0.75rem + 0.26vw, 1.0625rem); font-weight: bold; letter-spacing: 1px; line-height: 2; border-radius: 0; border: none;}
.sportsBannerTop .btn.btn-danger{background-color: var(--cm-cherryTwo); }
.sportsBannerTop .btn.btn-danger:hover{background-color: var(--cm-cherry);}
.entertainmentBannerTop .btn.btn-danger{background-color: var(--cm-skyeTwo);}
.entertainmentBannerTop .btn.btn-danger:hover{background-color: var(--cm-skye);}
.artsBannerTop .btn.btn-danger{background-color: var(--cm-yellow); color: #000; }
.artsBannerTop .btn.btn-danger:is(:hover,:active){background-color: var(--cm-yellowTwo);color: #000;}
