.sportsPage { padding-top: clamp(3.125rem, -1.033rem + 8.673vw, 9.375rem); padding-bottom: clamp(3.125rem, 1.878rem + 2.602vw, 5rem); }
.sportSourServices{padding: clamp(3.125rem, -1.033rem + 8.673vw, 9.375rem) 0 clamp(3.125rem, -0.367rem + 7.285vw, 8.375rem) 0; background-color: var(--cm-bodyBGLight); overflow: hidden;}
.ServicesList{ padding: clamp(3.125rem, 2.458rem + 1.076vw, 3.75rem) 0 0 0;}
.ServicesListBox { padding: clamp(1.5625rem, 0.939rem + 1.301vw, 2.5rem); padding-top: clamp(2.1875rem, 1.564rem + 1.301vw, 3.125rem); background-color: var(--cm-bodyBG); position: relative; display: flex; flex-direction: column; height: 100%; }
.ServicesListBox h3{ color: var(--cm-cherry); font-weight: bold; font-size: clamp(1.125rem, 0.959rem + 0.347vw, 1.375rem); margin-bottom: clamp(1.25rem, 0.834rem + 0.867vw, 1.875rem); letter-spacing: 1px;}
.ServicesListBox ul{ margin: 0; padding: 0; list-style: none;} 
.ServicesListBox li{ position: relative; padding-left: 22px;}
.ServicesListBox li:not(:first-child){ margin-top: 18px;}
.ServicesListBox li::before { content: "\2713"; color: var(--cm-cherry); font-weight: bold; font-size: 15px; position: absolute; left: 0;}
.oneLine::before,.twoLine::before,.threeLine::before,.fourLine::before{content:" ";height: 10px; background-size: cover; width: 100%; position: absolute; left: 0; top: 0;}
.oneLine::before{background: url('../images/oneLine.jpg') repeat center center;}
.twoLine::before{background: url('../images/twoLine.jpg') repeat center center;}
.threeLine::before{background: url('../images/threeLine.jpg') repeat center center;}
.fourLine::before{background: url('../images/fourLine.jpg') repeat center center;}
.aboutUsSports{ background-color: var(--cm-cherry);}
.text-lowercase{ text-transform: lowercase;}