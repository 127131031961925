/* Home */
.aboutText{padding: clamp(3.125rem, -2.475rem + 9.042vw, 8.375rem) 0;}
.lineLeft.sectionHeader h2::before { transform: translateX(0); left: 0; }

.cosmosServicesBox{ padding: clamp(3.125rem, -3.542rem + 10.764vw, 9.375rem) 0 clamp(3.125rem, -2.475rem + 9.042vw, 8.375rem) 0; overflow: hidden;}
.cosmosServicesBoxInner { padding: clamp(3.125rem, 2.458rem + 1.076vw, 3.75rem) 0 0 0; }
.cosmosServicesBoxCard{ background-color: var(--cm-bodyBGLight); padding: clamp(1.875rem, 1.043rem + 1.735vw, 3.125rem); display: flex; height: 100%; flex-direction: row;}
.ServicesCardIconText .ServicesCardIcon svg{ height: 100px; }
.ServicesCardIconText .ServicesCardText h2 { color: #FFF; text-transform: uppercase; font-weight: bold; letter-spacing: 1px; font-size: clamp(1.75rem, 1.593rem + 0.327vw, 2rem); margin: clamp(1.875rem, 1.169rem + 1.472vw, 3rem) 0; }
.ServicesCardIconText .ServicesCardText{color: var(--cm-purpleLightText);}
.ServicesCardIconText .ServicesCardText p{ line-height: 2.15; margin-bottom: 30px; min-height: 120px;}
.cosmosServicesBoxInner .row .col-md-6:nth-child(1) :is(.ServicesCardIcon svg){ fill: var(--cm-yellowTwo);}
.cosmosServicesBoxInner .row .col-md-6:nth-child(1) :is(.cosmosServicesBoxCard a span.linkLine){background-color: var(--cm-yellowTwo);}
.cosmosServicesBoxInner .row .col-md-6:nth-child(2) :is(.ServicesCardIcon svg){ fill: var(--cm-skyeTwo);}
.cosmosServicesBoxInner .row .col-md-6:nth-child(2) :is(.cosmosServicesBoxCard a span.linkLine){background-color: var(--cm-skyeTwo);}
.cosmosServicesBoxInner .row .col-md-6:nth-child(3) :is(.ServicesCardIcon svg){ fill: var(--cm-cherryTwo);}
.cosmosServicesBoxInner .row .col-md-6:nth-child(3) :is(.cosmosServicesBoxCard a span.linkLine){background-color: var(--cm-cherryTwo);}
/* Line Button */
.cosmosServicesBoxCard a{ position: relative; display: inline-block; vertical-align: middle; width: auto; margin: 0; font-size: 13px; line-height: 2.33em; font-weight: 400; outline: 0; -webkit-box-sizing: border-box; box-sizing: border-box; overflow: hidden; -webkit-transition: color .2s ease-in-out,background-color .2s ease-in-out,border-color .2s ease-in-out; -o-transition: color .2s ease-in-out,background-color .2s ease-in-out,border-color .2s ease-in-out; transition: color .2s ease-in-out,background-color .2s ease-in-out,border-color .2s ease-in-out; cursor: pointer; padding: 0!important; color: #FFF; background-color: transparent; border: 0; text-decoration: none; }
.cosmosServicesBoxCard a span.linkLine { position: absolute; z-index: 1; width: 3px; height: 100%; background-color: #4ec4ff; vertical-align: middle; -webkit-transition: all .5s cubic-bezier(.1,.1,0,1); -o-transition: all .5s cubic-bezier(.1,.1,0,1); transition: all .5s cubic-bezier(.1,.1,0,1); }
.cosmosServicesBoxCard a span.linkText { position: relative; z-index: 2; display: inline-block; vertical-align: middle; padding: 0 15px; -webkit-transition: 0s; -o-transition: 0s; transition: 0s; top: 4px; }
.cosmosServicesBoxCard a:hover span.linkLine{ width: 100%;}
.cosmosServicesBoxInner .row .col-md-6:nth-child(1) :is(.cosmosServicesBoxCard a:hover span.linkText){color: var(--cm-bodyBG);}

/* Our Portfolio */
.OurPortfolio .portfolio-thumb{overflow: hidden; background-size: cover !important; background-position: center; position: relative; display: flex; justify-items: center; align-items: center; aspect-ratio: 1 / 1;}
.OurPortfolio .portfolioOverlayBox .portfolioIcon { width: 100px; padding-bottom: clamp(3.125rem, 3.125rem + 0vw, 3.125rem);}
.OurPortfolio .portfolioTet h2{ text-transform: uppercase; font-size: clamp(2.25rem, 2.015rem + 0.491vw, 2.625rem); font-weight: bold;}
.portfolio{position: relative;}
:is(.portfolio.ov-img, .portfolio.ov-left, .portfolio.ov-right){position: absolute; background-repeat: no-repeat; z-index: 1;}
.portfolio.ov-img { filter: opacity(0.2);  width: 100%; height: 100%; background-size: cover; }
.portfolio.ov-left { filter: opacity(0.2);  width: 32.5%; height: 100%; background-position: left bottom; }
.portfolio.ov-right { filter: opacity(0.2); width: 38%; height: 100%; background-position: bottom right; bottom: 0; right: 0; }
.effect-3 { position: relative; overflow: hidden; }
.effect-3 .effect-img { font-size: 0; overflow: hidden; }
.effect-3 .effect-img img { width: 100%; height: auto; transition: .3s; }
.effect-3:hover .effect-img img { transform: scale(1.2); }
.effect-3 .effect-text { position: absolute; top: 0; left: 0; width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; flex-direction: column; padding: 30px; text-align: center;  transition: .5s; transform: scale(0); opacity: 0; }
.effect-3:hover .effect-text { transform: scale(1); opacity: 1; z-index: 2; }

.OurPortfolio .effect-3.Arts .portfolioOverlayBox{ color: #150427;}
.OurPortfolio .effect-3.Arts .effect-text { background: rgba(251, 234, 110, .8);}
.OurPortfolio .effect-3.Entertainment .effect-text { background: rgba(70, 199, 248, .8);}
.OurPortfolio .effect-3.sports .effect-text { background: rgba(191, 18, 44, .8);}
.homePortfolio {background-color: var(--cm-bodyBGLight);}