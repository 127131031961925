.letsConnectForm{padding: clamp(3.125rem, -3.542rem + 10.764vw, 9.375rem) 0; background: url('../images/LetsConnectImg.png') no-repeat right top; background-size: clamp(8.125rem, -8.838rem + 35.386vw, 33.625rem);}
.letsConnectFormBox{ padding-top:clamp(3.125rem, 1.949rem + 2.453vw, 5rem);}
.letsConnectFormBox .form-control { border-radius: 0; background-color: transparent; border: none; color: #FFF; padding: 0; font-size: 14px; }
.letsConnectFormBox .form-control:focus{ background-color: transparent; box-shadow: none; border-color: transparent; color: #FFF;}
.letsConnectFormBox .mb-3{ background-color: var(--cm-bodyBGLight); border-left:1px solid var(--cm-skye); padding: 15px;}
.letsConnectFormBox .mb-3.error_here{ border-left: 10px solid var(--cm-cherryTwo); }
:is(.letsConnectFormBox) .mb-3.error_here .form-label, label span{color: var(--cm-cherryTwo); }
.letsConnectFormBox label{ color: var(--cm-skye); font-size: 14px;}
.letsConnectFormBox textarea.form-control{ resize: none;}
.letsConnectFormBox .form-control.form-select{padding: 10px; margin:-10px;}
.letsConnectFormBox .btn.btn-primary{ text-transform: uppercase; font-size: clamp(0.875rem, 0.75rem + 0.26vw, 1.0625rem); font-weight: bold; letter-spacing: 1px; line-height: 2; border-radius: 0; border: none; background-color: transparent; padding: 0 0 0 20px; position: relative; }
.letsConnectFormBox .btn.btn-primary::after { content: ''; position: absolute; z-index: -1; top: 0; bottom: 0; right: 0; width: 0; background-color: var(--cm-skye); transition: all .5s cubic-bezier(.1,.1,0,1);}
.letsConnectFormBox .btn.btn-primary:hover::after{width: 100%;}
.letsConnectFormBox .btn.btn-primary span { width: 50px; height: 50px; display: inline-flex; justify-content: center; align-items: center; background-color: var(--cm-skye); margin-left: 15px;}
.letsConnectFormBox .btn.btn-primary svg{ height: 14px;}
.letsConnectFormBox .btn.btn-primary:active{ background-color: transparent;}
input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus { -webkit-text-fill-color: #FFF; -webkit-box-shadow: 0 0 0px 1000px #230741 inset; transition: background-color 5000s ease-in-out 0s; }
.form-select.form-control option{ background-color: #230741; padding: 10px; font-family: Arial, Helvetica, sans-serif;}
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button { -webkit-appearance: none; margin: 0; }
input[type=number] { -moz-appearance: textfield; }