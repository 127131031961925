@font-face {
    font-family: 'Samsung Sharp Sans';
    src: url('SamsungSharpSansRegular-Regular.eot');
    src: url('SamsungSharpSansRegular-Regular.eot?#iefix') format('embedded-opentype'),
        url('SamsungSharpSansRegular-Regular.woff2') format('woff2'),
        url('SamsungSharpSansRegular-Regular.woff') format('woff'),
        url('SamsungSharpSansRegular-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Samsung Sharp Sans';
    src: url('SamsungSharpSans-Bold.eot');
    src: url('SamsungSharpSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('SamsungSharpSans-Bold.woff2') format('woff2'),
        url('SamsungSharpSans-Bold.woff') format('woff'),
        url('SamsungSharpSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}