@import "../src/resources/Fonts/SamsungSharpSans.css";
:root{
  --cm-bodyBG:#150427;
  --cm-bodyBGLight:#230741;
  --cm-skye:#63b0e6;
  --cm-skyeTwo:#46c7f8;
  --cm-yellow:#f2dfaa;
  --cm-yellowTwo:#fbea6e;
  --cm-purpleLight:#a068b1;
  --cm-purpleLightText:#b679cc;
  --cm-cherry:#a22b47;
  --cm-cherryTwo:#bf122c;
  --cm-ff-SamsungSharp :'Samsung Sharp Sans';
}
body{background-color: var(--cm-bodyBG); color: #FFF; font-family:var(--cm-ff-SamsungSharp); font-size: 14px; line-height: 1.5em;}
a{-webkit-transition: .4s ease; -o-transition: .4s ease; transition: .4s ease;}
a:focus-visible{outline: none;}
p:empty{display: none;}
P:last-child{margin: 0;}
.container{ max-width: 1450px;}
/* section Header */
.sectionHeader h2{font-size:clamp(2.375rem, 2.108rem + 0.431vw, 2.625rem); letter-spacing: 1px; font-weight: bold; font-family: var(--cm-ff-SamsungSharp); margin-bottom: 24px; position: relative; padding-top: 40px;}
.sectionHeader h2::before {content: ' '; width: 140px; height: 10px; background-color: var(--cm-purpleLight); position: absolute; top: 0; left: 50%; transform: translateX(-50%);}
.skyeLine.sectionHeader h2::before {background-color: var(--cm-skye); }
.yellowLine.sectionHeader h2::before {background-color: var(--cm-yellow);}
.cherryLine.sectionHeader h2::before{background-color: var(--cm-cherry);}
.purpleLine.sectionHeader h2::before{background-color: var(--cm-purpleLight);}
.whiteLine.sectionHeader h2::before{background-color: #FFF;}

.mb-0{ margin-bottom: 0 !important;}

/* header */
.headerNav .bg-dark{ background-color: var(--cm-bodyBG) !important; color: #FFF; padding-top: 15px; padding-bottom: 15px;}
.navbar-brand img { width: 75px; }
.DrawerTheme .MuiDrawer-paper{background-color: var(--cm-bodyBG) !important;  width: clamp(17.5rem, 12.156rem + 14.87vw, 30rem);}
.DrawerTheme .MuiModal-backdrop{background-color: rgba(35, 7, 65, 0.8) !important;}
.rightDrawer { padding: clamp(1.5625rem, 0.895rem + 1.859vw, 3.125rem); display: flex; justify-content: space-between; align-items: flex-start;}
.rightDrawer a.rightDrawerLogo img { width: 120px; }
.rightMenu{ margin: 0; padding:10px 0; list-style: none;}
.rightMenu li{ padding: 0; position: relative;}
.rightMenu a { color: #FFF; text-decoration: none; text-transform: uppercase; font-size: 15px; letter-spacing: 1px; font-weight: bold; width: 100%; padding: clamp(0.9375rem, 0.646rem + 0.607vw, 1.375rem) clamp(1.5625rem, 0.895rem + 1.859vw, 3.125rem); }
.rightMenu a:is(:hover,:focus){ color: #FFF;}
.rightMenu a::after{content: ''; position: absolute; z-index: -1; top: 0; bottom: 0; right: 0; width: 0; background-color: var(--cm-cherry); -webkit-transition: all .5s cubic-bezier(.1,.1,0,1); -o-transition: all .5s cubic-bezier(.1,.1,0,1); transition: all .5s cubic-bezier(.1,.1,0,1); }
.rightMenu.artsNav a:hover{ color: #000;}
.rightMenu.artsNav a:hover::before, .rightMenu.artsNav a.nav-link.active::before{ background: url('../src/resources/images/menu-icon-black.svg') no-repeat center left; content: ' '; position: absolute; z-index: 0; top: 0; bottom: 0; right: 0; width: 33px; height: 100%; background-size: cover; }
.rightMenu.artsNav a::after{ background-color: var(--cm-yellow);}
.rightMenu.entertainmentsNav a::after{ background-color: var(--cm-skye);}
.rightMenu a:hover::after, .rightMenu a.nav-link.active::after{width: 100%;}
.rightMenu a:hover::before, .rightMenu a.nav-link.active::before { content: ' '; position: absolute; z-index: 0; top: 0; bottom: 0; right: 0; width: 33px; background: url('../src/resources/images/menu-icon.svg') no-repeat center left; height: 100%; background-size: cover; }
.headerNav .navbar-nav .nav-link { text-transform: uppercase; letter-spacing: 1px; font-size: 15px; font-weight: bold; position: relative; color: #FFF; padding: 8px 15px; cursor: pointer;}
.headerNav .navbar-nav .nav-item:not(:first-child) {margin-left: 8px;}
.headerNav .navbar-nav .nav-link::before{ content: ''; position: absolute; z-index: -1; top: 0; bottom: 0; left: 0; width: 0; -webkit-transition: all .5s cubic-bezier(.1,.1,0,1); -o-transition: all .5s cubic-bezier(.1,.1,0,1); transition: all .5s cubic-bezier(.1,.1,0,1); }
.rightMenu a.nav-link.active{ color: #FFF;}


:is(.artsNavTop .nav-item, .artsNavTop.nav-item) .nav-link:hover, .nav-link.active{color: #000;}
:is(.portfolioNavTop .nav-item, .portfolioNavTop.nav-item) .nav-link::before{background-color: var(--cm-purpleLight);}
:is(.artsNavTop .nav-item, .artsNavTop.nav-item) .nav-link::before{background-color: var(--cm-yellow);}
:is(.entertainmentNavTop .nav-item, .entertainmentNavTop.nav-item) .nav-link::before{background-color: var(--cm-skye);}
:is(.sportsNavTop .nav-item, .sportsNavTop.nav-item) .nav-link::before{background-color: var(--cm-cherry);}
.artsNavTop .nav-link.active, .artsNav .nav-link.active{color: #000 !important;}

.headerNav .navbar-nav .nav-link.active::before{width: 100%;}
.headerNav .navbar-nav .nav-link:hover::before{width: 100%;}
.rightDrawer .closeButton{ padding: 0; color: #FFF; min-width: auto; margin-top: -10px;}
.rightDrawer .closeButton svg{ width: 24px; }
.rightDrawer .closeButton:hover svg, .barButton button:hover svg{ fill: var(--cm-skye);}
.barButton{ margin-left: 15px;}
.barButton button{ color: #FFF;}
.barButton button svg { width: 24px; }
.topRightNav.rightMenu li.portfolioRight a::after{background-color: var(--cm-purpleLight);}
.topRightNav.rightMenu li.artsRight a::after{background-color: var(--cm-yellow);}
.topRightNav.rightMenu li.entertainmentRight a::after{background-color: var(--cm-skye);}
.topBgLineNav{position: relative; margin-top: 20px;}
.topBgLineNav::before{content:" "; background: url('../src/resources/images/oneLine.jpg') repeat center center;height: 10px; background-size: cover; width: 100%; position: absolute; left: 0; top: 0;}
.topRightNav.rightMenu li.artsRight a.nav-link.active{background-color: var(--cm-yellow); color: #000;}
.topRightNav.rightMenu li.artsRight a.nav-link.active:hover{color: #000;}
.topRightNav.rightMenu li.artsRight a.nav-link:hover{color: #000;}
.topRightNav.rightMenu li.artsRight a.nav-link:hover::before{background: url('../src/resources/images/menu-icon-black.svg') no-repeat center left; content: ' '; position: absolute; z-index: 0; top: 0; bottom: 0; right: 0; width: 33px; height: 100%; background-size: cover;}
.topRightNav.rightMenu li.artsRight a.nav-link.active::before{background: url('../src/resources/images/menu-icon-black.svg') no-repeat center left; content: ' '; position: absolute; z-index: 0; top: 0; bottom: 0; right: 0; width: 33px; height: 100%; background-size: cover;}
.topRightNav.rightMenu li.entertainmentRight a.nav-link.active{background-color: var(--cm-skye); color: #FFF;}
.topRightNav.rightMenu li.entertainmentRight a.nav-link.active:hover{color: #FFF;}
.topRightNav.rightMenu li.entertainmentRight a.nav-link.active::before{background: url('../src/resources/images/menu-icon.svg') no-repeat center left; content: ' '; position: absolute; z-index: 0; top: 0; bottom: 0; right: 0; width: 33px; height: 100%; background-size: cover;}
.topRightNav.rightMenu li.sportsRight a.nav-link.active:hover{color: #FFF;}
.topRightNav.rightMenu li.sportsRight a.nav-link.active::before{background: url('../src/resources/images/menu-icon.svg') no-repeat center left; content: ' '; position: absolute; z-index: 0; top: 0; bottom: 0; right: 0; width: 33px; height: 100%; background-size: cover;}
.topRightNav.rightMenu li.sportsRight a.nav-link.active{background-color: var(--cm-cherry); color: #FFF;}

.artsBannerTop{ border-top: 10px solid var(--cm-yellow);}
.entertainmentBannerTop{ border-top: 10px solid var(--cm-skye);}
.sportsBannerTop{ border-top: 10px solid var(--cm-cherry);}
.btn:focus-visible{ box-shadow: none;}
.portfolio-thumb a{ color: #FFF;}

.OurPartnersLogoVideoInner .videoPlayer video{ aspect-ratio: 1 / 1;}
@media screen and (min-width: 992px) { .OurPartnersLogoVideoInner{ padding-right: 50px;} }
@media (min-width: 992px) { .col-md-1-5 { width: 20%; } .col-md-2-5 { width: 40%; } .col-md-3-5 { width: 60%; } .col-md-4-5 { width: 80%; } .col-md-5-5 { width: 100%; } }
@media (min-width: 1200px) { .col-lg-1-5 { width: 20%; } .col-lg-2-5 { width: 40%; } .col-lg-3-5 { width: 60%; } .col-lg-4-5 { width: 80%; } .col-lg-5-5 { width: 100%; } }

/* Footer */
.footerSection{ background-color: #0a0213; padding: clamp(3.125rem, -1.033rem + 8.673vw, 9.375rem) 0 50px 0; position: relative;}
.footerLine{height: 10px; width: 100%; position: absolute;bottom: 0; font-size: 0;}
.footerLine span{background:  url('../src/resources/images/footerLinebg.jpg') no-repeat center bottom; display: block; height: 10px; display: block; height: 10px; position: relative; z-index: 1;}
.footerLine::before, .footerLine::after{ content: '';  z-index: 0; width: 50%; position: absolute; height: 10px; top: 0;}
.footerLine::before {background-color: var(--cm-yellow); left: 0;}
.footerLine::after { background-color: var(--cm-purpleLight); right: 0;}
.copyright{ font-size: 13px;}
.copyright a{ color: #fff; text-decoration: none;}
.copyright a:is(:hover,:focus){ color: var(--cm-skye);}
.copyright span{color: var(--cm-skye);}
.footerInfoLogo img{ max-width: 150px;}
.footerInfoText { padding: 50px 0 clamp(3.125rem, 1.462rem + 3.469vw, 5.625rem) 0;}
.socialMediaList{ margin-top: 50px;}
.socialMediaList ul { margin: 0; padding: 0; list-style: none; display: flex; justify-content: center; align-items: center; }
.socialMediaList li{ margin: 0 13px;}
.socialMediaList a{text-decoration: none; color: var(--cm-skye);}
.socialMediaList a:is(:hover){color: var(--cm-purpleLight);}
.socialMediaList a svg{ width: 26px;}
.socialMediaList .tiktokIcon a svg{width: 22px;}
.footerInfoEmail ul{margin: 0; padding: 0; list-style: none;}
.footerInfoEmail li { display: inline-flex; margin: 0 13px; justify-content: center; align-items: center; margin-top: 24px; }
.footerInfoEmail li:first-child { display: flex; justify-content: center; align-items: center; margin-top: 0; }
.footerInfoEmail li span{ display: inline-flex; margin-right: 15px;}
.footerInfoEmail li p {line-height: 1.5;}
.footerInfoEmail li p span{ display: inline-flex; margin-right: 7px; margin-left: 7px;}
.footerInfoEmail li svg{ width: 20px; fill: var(--cm-skye);}
.footerInfoEmail a{ color: #FFF; text-decoration: none;}
.footerInfoEmail a:hover{ color: var(--cm-skye);}
.footerInfoEmail p{ margin: 0; line-height: 1.2; font-size: 15px;}
@media screen and (max-width: 767.98px) {
.copyright { text-align: center;}
.ServicesCardIconText .ServicesCardText p{min-height: auto;}
}
